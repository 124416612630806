<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
  >
    <b-row>
      <b-col
          cols="12"
          class="mb-2"
      >
        <div class="d-flex justify-content-end">
          <router-link
              :to="{ name: 'exam-edit' }"
              class="btn btn-danger btn-sm"
              variant="danger"
          >{{ $t('Add') }}
          </router-link>
        </div>
      </b-col>
      <b-col
          cols="12"
          class="form_custom"
      >
        <b-card-code title="">
          <b-row>
            <b-col cols="6">
              <b-button
                  variant="outline-danger"
                  class="position-absolute position-bottom-0 btn-sm"
                  :disabled="!ids.length"
                  @click="deleteMulti"
              >
                <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                />
                <span class="align-middle">{{ $t('Delete') }}</span>
              </b-button>
            </b-col>
            <b-col md="3">
              <label for="">
                {{ $t("type") }}
              </label>
              <b-form-select
                  v-model="filter.type"
                  :options="typeOptions"
                  @change="loadItems"
              />
            </b-col>

            <b-col md="3">
              <label for="">
                {{ $t("Search") }}
              </label>
              <b-form-input
                  v-model="filter.key"
                  :placeholder="$t('search_key')"
                  @change="loadItems"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <table
                  class="table b-table"
                  style="margin-top: 10px"
              >
                <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                        v-model="cbxAll"
                        class="custom-control-danger"
                        name="check-button"
                        @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                      scope="col"
                      width="10"
                  >
                    STT
                  </th>
                  <th
                      scope="col"
                      width="300"
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                      scope="col"
                      width="300"
                  >
                    {{ $t("type") }}
                  </th>
                  <th
                      scope="col"
                      width="300"
                  >
                    {{ $t("point") }}
                  </th>
                  <th
                      scope="col"
                      width="200"
                  >
                    Tag
                  </th>
                  <th
                      scope="col"
                      width="200"
                  >
                    {{ $t("status") }}
                  </th>
                  <th
                      width="100"
                      scope="col"
                      :aria-sort="getSortDirection('created_at')"
                      @click="changeSortDirection('created_at')"
                  >
                    {{ $t('crt') }}
                  </th>
                  <th width="100">
                    {{ $t("opiton") }}
                  </th>
                </tr>
                </thead>
                <draggable
                    v-model="rows"
                    tag="tbody"
                    @end="onEnd"
                >
                  <tr
                      v-for="(item, index) in rows"
                      :id="item.id"
                      :key="item.id"
                  >
                    <td>
                      <b-form-checkbox
                          v-model="ids"
                          :value="item.id"
                          class="custom-control-danger"
                          name="check-button"
                      />
                    </td>
                    <td scope="row">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <router-link
                          class="text-black"
                          :to="{ name: 'exam-edit', params: { id: item.id, name: item.name }}"
                      >
                        <strong class="text_name">{{ item.name }}</strong>
                      </router-link>
                    </td>
                    <td>
                      <template v-if="item.type === 1">
                        Trắc nghiệm
                      </template>
                      <template v-else>
                        Điền từ
                      </template>
                    </td>
                    <td> {{ item.point }}</td>
                    <td>
                      <div
                          v-if="item.tags.length"
                          class="demo-inline-spacing"
                      >
                        <b-badge
                            v-for="(tag, index) in item.tags"
                            :id="index"
                            variant="light-danger"
                        >
                          {{ tag.name }}
                        </b-badge>
                      </div>
                    </td>
                    <td>
                      <b-form-checkbox
                          v-model="item.status"
                          class="custom-control-danger"
                          name="check-button"
                          switch
                          @change="val => changeStatus(item.id, val)"
                      />
                    </td>
                    <td>{{ convertDate(item.created_at) }}</td>
                    <td>
                      <b-dropdown
                          id="dropdown-1"
                          class="custom-position-dropdown"
                          variant="flat-sencondary"
                      >
                        <template #button-content>
                          <feather-icon
                              icon="MoreHorizontalIcon"
                              size="16"
                              class="align-middle"
                          />
                        </template>
                        <div
                            v-b-modal.modal-edit
                            class="dropdown-item"
                            @click="resetModal(false, item)"
                        >
                          {{ $t('edit') }}
                        </div>
                        <div
                            class="dropdown-item"
                            @click="deleteMulti(item.id)"
                        >{{ $t('Delete') }}</div>
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
              </table>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  class="pagination-danger mt-2"
                  align="right"
                  @input="onPageChange"
              >
                <template #prev-text>
                  <feather-icon
                      size="18"
                      icon="ChevronLeftIcon"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      size="18"
                      icon="ChevronRightIcon"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>

        </b-card-code>
        <b-modal
            id="modal-edit"
            v-model="modalShow"
            :ok-title="$t('save_info')"
            ok-variant="danger"
            :cancel-title="$t('cancel')"
            centered
            size="lg"
            :title="titleAdd"
            @ok.prevent="submit "
        >
          <validation-observer ref="addQuestion">
            <b-form class="form_custom">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Tên đăng nhập"
                        rules="required"
                    >
                      <label :class="errors.length > 0 ? 'text-danger': null">
                        {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                      <b-form-input
                          id="name"
                          v-model="frm.name"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        :name="'tag'"
                        rules="required"
                    >
                      <label
                          :class="errors.length > 0 ? 'text-danger': null"
                      >Tag <span class="text-danger">(*)</span></label>
                      <v-select
                          id="tag"
                          v-model="frm.tag"
                          multiple
                          class=""
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="tagOptions"
                          :reduce="label => label.id"
                          :state="errors.length > 0 ? false:null"
                          :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <validation-provider
                  #default="{ errors }"
                  :name="'answers'"
                  rules="required"
              >
                <label
                    :class="errors.length > 0 ? 'text-danger': null"
                >{{ $t('ans') }} <span class="text-danger">(*)</span></label>
                <div
                    v-show="isShow"
                    class="box_content_quiz"
                >
                  <b-row class="align-items-stretch">
                    <b-col cols="9">
                      <div class="quiz_content_left">
                        <b-row>
                          <b-col md="6">
                            <validation-provider
                                #default="{ errors }"
                                name="loại câu hỏi"
                                vid="type"
                                rules="required"
                            >
                              <b-form-group>
                                <v-select
                                    id="type"
                                    v-model="frm.type"
                                    class=""
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="label"
                                    :options="questionTypeData"
                                    :reduce="label => label.value"
                                    :state="errors.length > 0 ? false:null"
                                    :clearable="false"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <template
                            v-if="frm.type === 1 || frm.type === 3"
                        >
                          <b-list-group class="group_quiz_create">
                            <draggable
                                v-model="frm.answers"
                                tag=""
                                @end="value => onEnd(value)"
                            >
                              <b-list-group-item
                                  v-for="(value, index2) in frm.answers"
                                  :key="index2"
                                  class="item_quiz_create"
                              >
                                <b-row>
                                  <b-col cols="1">
                                    <b-badge
                                        variant="light-danger"
                                        class="badge-light-custom"
                                    >
                                      {{ answerIndex[index2] }}
                                    </b-badge>
                                  </b-col>
                                  <b-col cols="9">
                                    <div>
                                      <validation-provider
                                          #default="{ errors }"
                                          name="đáp án"
                                          vid="answer_name"
                                          rules="required"
                                      >
                                        <template v-if="frm.type === 1">
                                          <b-form>
                                            <quill-editor
                                                v-model="value.name"
                                                :options="snowOption"
                                                :placeholder="'Nhập đáp án'"
                                                max-rows="6"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form>
                                        </template>

                                        <template v-else-if="frm.type === 3">
                                          <b-form-textarea
                                              v-model="value.name"
                                              placeholder="Nhập đáp án"
                                              rows="3"
                                              max-rows="6"
                                              :state="errors.length > 0 ? false:null"
                                          />
                                        </template>
                                      </validation-provider>
                                    </div>
                                  </b-col>
                                  <b-col
                                      cols="1"
                                      class="border-checked-none"
                                  >
                                    <b-input-group-append
                                        v-if="frm.type ===1"
                                        style="margin-top: 70px"
                                        is-text
                                        class="custom_radio_checker"
                                    >
                                      <b-form-radio
                                          v-model="frm.is_correct"
                                          :value="index2"
                                          :name="`radio-input-${value.id}`"
                                      />
                                    </b-input-group-append>
                                  </b-col>
                                  <b-col cols="1">
                                    <feather-icon
                                        style="margin-top: 70px"
                                        class="btn_close_item"
                                        icon="XIcon"
                                        @click="removeAnswer(index2)"
                                    />
                                  </b-col>
                                </b-row>
                              </b-list-group-item>
                            </draggable>
                          </b-list-group>
                          <b-button
                              v-show="frm.type !== 2"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-secondary"
                              class="btn-icon float-left mt-1 button_create_ansewer"
                              style=""
                              @click="addAnswer"
                          >
                            {{ $t('addNewAnswer') }}
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                        </template>
                        <b-row>
                          <b-col cols="12" />
                        </b-row>
                      </div>
                    </b-col>
                    <b-col
                        cols="3"
                    >
                      <div class="block_upload_group">
                        <div>
                          <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                name="ảnh"
                                vid="image"
                            >
                              <b-form-file
                                  v-model="frm.image"
                                  class="input_file updload_file_img"
                                  placeholder=""
                                  drop-placeholder="Drop file here..."
                                  accept="image/*"
                                  :state="errors.length > 0 ? false:null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                            <div
                                v-if="frm.image"
                                class="my-1"
                            >
                              File đã chọn: <strong>{{ frm.image.name }}</strong>
                            </div>
                            <b-card-text
                                v-if="imgUrl"
                                class="mt-1"
                                style="position: relative"
                            >
                              <!--                              <span-->
                              <!--                                class="btn_remove_imgUpload"-->
                              <!--                                @click="deleteMedia(item.id,1)"-->
                              <!--                              >-->
                              <!--                                <svg-->
                              <!--                                  xmlns="http://www.w3.org/2000/svg"-->
                              <!--                                  fill="currentColor"-->
                              <!--                                  viewBox="0 0 512 512"-->
                              <!--                                ><path-->
                              <!--                                  d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"-->
                              <!--                                /></svg>-->
                              <!--                              </span>-->
                              <b-img
                                  center
                                  class="mb-1 mb-sm-0"
                                  height="150"
                                  :src="endPoint + '/storage' + imgUrl"
                                  alt="Right image"
                              />
                            </b-card-text>
                          </b-form-group>

                          <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                name="audio"
                                vid="audio"
                            >
                              <b-form-file
                                  v-model="frm.audio"
                                  class="input_file updload_file_audio"
                                  placeholder=""
                                  drop-placeholder="Drop file here..."
                                  accept=".mp3"
                                  :state="errors.length > 0 ? false:null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <div
                                v-if="frm.audio"
                                class="my-1"
                            >
                              File đã chọn: <strong>{{ frm.audio ? frm.audio.name : '' }}</strong>
                            </div>
                            <div
                                v-if="audioUrl"
                                style="position: relative;"
                            >
                              <!--                              <audio-->
                              <!--                                controls="controls"-->
                              <!--                                style="width: 352px;margin-top: 30px"-->
                              <!--                              >-->
                              <!--                                <source-->
                              <!--                                  :src="endPoint + '/storage' + audioUrl"-->
                              <!--                                  type="audio/mp3"-->
                              <!--                                >-->
                              <!--                              </audio>-->
                              <b-embed
                                  type="audio"
                                  aspect="4by3"
                                  controls
                                  poster="poster.png"
                              >
                                <source
                                    :src="endPoint + '/storage' + audioUrl"
                                    type="video/webm"
                                >
                              </b-embed>
                              <!--                              <span-->
                              <!--                                class="btn_remove_imgUpload"-->
                              <!--                                @click="deleteMedia(item.id,0)"-->
                              <!--                              >-->
                              <!--                                <svg-->
                              <!--                                  xmlns="http://www.w3.org/2000/svg"-->
                              <!--                                  fill="currentColor"-->
                              <!--                                  viewBox="0 0 512 512"-->
                              <!--                                ><path-->
                              <!--                                  d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"-->
                              <!--                                /></svg>-->
                              <!--                              </span>-->
                            </div>
                          </b-form-group>
                        </div>

                        <div class="d-flex align-items-sm-center justify-content-flex-end;">
                          <b-form-group style="width: 100%">
                            <validation-provider
                                #default="{ errors }"
                                name="điểm"
                                vid="point"
                                rules="required"
                            >
                              <label
                                  for="point"
                                  :class="errors.length > 0 ? 'text-danger' : ''"
                              >{{ $t('point') }} <span class="text-danger">(*)</span></label>
                              <b-form-input
                                  id="point"
                                  v-model="frm.point"
                                  type="number"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="$t('point')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
              <b-row />
              <b-row>
                <b-col md="6">
                  <div class="form-label-group d-flex">
                    <b-form-checkbox
                        id="status1"
                        v-model="frm.status"
                        class="custom-control-danger"
                        name="is_active"
                        switch
                    />
                    <div>{{ $t('status') }}</div>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>

      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import config from '@/config'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BImg,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      modalShow: false,
      currentPage: 1,
      showAddAnswer: false,
      typeOptions: [
        { value: 'all', text: this.$t('all') },
        { value: 1, text: this.$t('multiple_c') },
        { value: 3, text: this.$t('fill') },
      ],
      frm: {
        name: null,
        tag: [],
        is_correct: null,
        status: false,
        type: null,
        multiple_choice_type: null,
        point: 0,
        answers: [],
        image: null,
        audio: null,
      },
      pageLength: 10,
      isShow: true,
      editModal: false,
      dragging: false,
      titleAdd: this.$t('Add'),
      file: null,
      // eslint-disable-next-line global-require
      imgUrl: null,
      required,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('enter_q'),
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
        type: 'all',
        statusOptions: [
          { value: 'all', text: 'Tất cả' },
          { value: 1, text: 'Hoạt động' },
          { value: 0, text: 'Không hoạt động' },
        ],
      },
      sort: {
        by: 'order',
        direction: 'desc',
      },
      answerIndex: config.course.answerIndex,
      questionTypeData: [
        {
          label: this.$t('multiple_c'),
          value: 1,
        },
        {
          label: this.$t('fill'),
          value: 3,
        },
      ],
      audioUrl: null,
      tagOptions: [],

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
    this.loadTags()
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/questions', {
            data: {
              ids: this.ids,
            },
          })
              // eslint-disable-next-line no-unused-vars
              .then(res => {
                this.$bvToast.toast(this.$t('message.delete_success'), {
                  title: this.$t('message.noitify'),
                  icon: 'BellIcon',
                  variant: 'success',
                  solid: false,
                })
                this.loadItems()
                // eslint-disable-next-line no-unused-vars
              }).catch(e => {
            console.log('e', e)
            this.$bvToast.toast(this.$t('message.delete_err'), {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'danger',
              solid: false,
            })
          }).finally(() => {
            this.isLoading = false
            this.ids = []
          })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/questions', { oldOrder, newOrder })
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$bvToast.toast(this.$t('message.update_success'), {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'success',
              solid: false,
            })
            // eslint-disable-next-line no-unused-vars
          }).catch(e => {
        this.$bvToast.toast(this.$t('message.update_err'), {
          title: this.$t('message.notify'),
          icon: 'BellIcon',
          variant: 'danger',
          solid: false,
        })
      }).finally(() => {
        this.isLoading = false
        this.loadItems()
      })
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/questions', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          key: this.filter.key,
          status: this.filter.status,
          type: this.filter.type,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
          .then(res => {
            console.log('res.data.total', res.data.total)
            this.total = res.data.total
            this.rows = res.data.data.map(v => {
              // eslint-disable-next-line no-param-reassign
              v.status = v.status === 1
              return v
            })
          }).catch()
          .finally(() => {
            this.isLoading = false
          })
    },
    loadTags() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          order_by: this.sort.by,
        },
      })
          .then(res => {
            this.tagOptions = res.data.data
          }).catch()
          .finally(() => {
            this.isLoading = false
          })
    },

    onFileChange(e) {
      const file = e.target.files[0]
      this.imgUrl = URL.createObjectURL(file)
      console.log('this.imgUrl', this.imgUrl)
    },
    resetModal(flag, question = false) {
      this.titleAdd = flag ? this.$t('Add') : this.$t('Edit')
      // eslint-disable-next-line no-multi-assign
      this.frm.name = this.imgUrl = this.frm.type = this.frm.image = this.frm.audio = this.frm.multiple_choice_type = null
      this.frm.tag = []
      this.frm.answers = []
      this.frm.status = false
      this.imgUrl = null
      this.editModal = false
      this.audioUrl = null
      if (flag) {
        this.frm.answers.push({
          name: '',
          is_correct: false,
        })
      }
      if (question) {
        this.frm.name = question.name
        this.frm.status = question.status
        this.frm.type = question.type
        this.frm.point = question.point
        this.frm.answers = question.answers
        this.editModal = question.id
        question.answers.map((val, index) => {
          if (val.is_correct === 1) {
            this.frm.is_correct = index
          }
        })
        const arr = []
        question.tags.forEach(item => {
          arr.push(item.id)
        })
        this.frm.tag = arr

        question.files.forEach(val => {
          if (val.extension === 1) {
            this.imgUrl = val.url
          } else if (val.extension === 2) {
            this.audioUrl = val.url
          }
        })
        console.log(this.imgUrl)
      }
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    changeStatus(id, status) {
      this.isLoading = true
      this.$http.put(`/questions/${id}`, { status })
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$bvToast.toast(this.$t('message.update_success'), {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'success',
              solid: false,
            })
            // eslint-disable-next-line no-unused-vars
          }).catch(e => {
        this.$bvToast.toast(this.$t('message.update_err'), {
          title: this.$t('message.notify'),
          icon: 'BellIcon',
          variant: 'danger',
          solid: false,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    submit() {
      this.$refs.addQuestion.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          if (this.frm.image) formData.append('image', this.frm.image)
          if (this.frm.audio) formData.append('audio', this.frm.audio)
          this.frm.answers = this.frm.answers.map((v, index) => {
            // eslint-disable-next-line no-param-reassign
            v.is_correct = false
            if (index === this.frm.is_correct || this.frm.type === 3) {
              v.is_correct = true
            }
            return v
          })
          formData.append('name', this.frm.name)
          formData.append('tag', JSON.stringify(this.frm.tag))
          formData.append('status', this.frm.status ? 1 : 0)
          formData.append('type', this.frm.type)
          formData.append('point', this.frm.point)
          formData.append('answers', JSON.stringify(this.frm.answers))

          const url = this.editModal ? `/questions/${this.editModal}` : '/questions'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

              // eslint-disable-next-line no-unused-vars
              .then(res => {
                this.modalShow = false
                this.loadItems()
                this.resetModal()

                this.$bvToast.toast(this.$t('message.update_success'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'success',
                  solid: false,
                })
              })
              // eslint-disable-next-line no-unused-vars
              .catch(error => {
                console.log('error', error.response)
                let errorMsg = {}
                if (error.response.status === 422) {
                  errorMsg = error.response.data
                } else {
                  errorMsg.name = [error.response.data.error]
                }
                this.$refs.addQuestion.setErrors(errorMsg)
                this.$bvToast.toast(this.$t('message.update_err'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'danger',
                  solid: false,
                })
              }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
    addAnswer() {
      if (this.frm.answers.length < 9) {
        this.frm.answers.push({
          name: this.add_answer,
          is_correct: false,
        })

        this.add_answer = null
      }
    },
    removeAnswer(index) {
      if (this.frm.type === 1 && this.frm.multiple_choice_type === 1) {
        if (index < this.frm.is_correct) {
          this.frm.is_correct -= 1
        } else if (index === this.frm.is_correct) {
          this.frm.is_correct = null
        }
      }

      this.frm.answers.splice(index, 1)
    },
  },
}
</script>
