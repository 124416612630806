export default {
  course: {
    type: [
      {
        label: 'Trả phí',
        value: 'pay_fee',
      },
      {
        label: 'Tặng',
        value: 'donate',
      },
    ],
    answerIndex: [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
    ],
  },
  skill: {
    type: [
      {
        label: 'Kiểm tra đầu vào',
        value: 2,
      },
      {
        label: 'Thi thử miễn phí',
        value: 3,
      },
      {
        label: 'Kiểm tra trình độ',
        value: 4,
      },
      {
        label: 'Kiểm tra định kỳ',
        value: 5,
      },
    ],
  },
  source: [
    {
      text: 'Tất cả',
      value: 'all',
    },
    {
      text: 'Website',
      value: 0,
    },
    {
      text: 'Google',
      value: 1,
    },
    {
      text: 'Facebook',
      value: 2,
    },
    {
      text: 'Page',
      value: 3,
    },
    {
      text: 'Ads',
      value: 4,
    },

  ],
  type_exercise: [
    {
      label: 'Bài tập',
      value: 1,
    },
    {
      label: 'Kiểm tra đầu vào',
      value: 2,
    },
    {
      label: 'Thi thử miễn phí',
      value: 3,
    },
    {
      label: 'Kiểm tra trình độ',
      value: 4,
    },
    {
      label: 'Kiểm tra định kỳ',
      value: 5,
    },
  ],
  type_question: [
    {
      text: 'Điền từ',
      icon: 'GridIcon',
      to: 'fill-answer',
      type: 5,
    },
    {
      text: 'Ghép cột',
      icon: 'MoveIcon',
      to: 'column-merge',
      type: 4,
    },
    {
      text: 'Ghép từ',
      icon: 'ShuffleIcon',
      to: 'compound-word',
      type: 3,
    },
    {
      text: 'Ghi âm',
      icon: 'MicIcon',
      to: 'recording',
      type: 2,
    },
    {
      text: 'Trắc nghiệm',
      icon: 'DiscIcon',
      to: 'multiple-choice',
      type: 1,
    },
  ],
}
